/*
 * Copyright 2019 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";

$table-border-color: $line-color;
$table-header-bg: $secondary-bg;
$table_background_color: #fff;
$icon_disable_color: #ccc;
$draggable-row-color: #e2f7fa;

.table {
  $table-border-top-color: #dee2e6;
  border:          1px solid $table-border-color;
  border-collapse: collapse;
  display:         table;
  width:           100%;
  background:      $table_background_color;

  thead {
    font-size:      13px;
    text-transform: uppercase;
    background:     $table-header-bg;
    border-radius:  10px 10px 0 0;
    vertical-align: middle;

  }

  th {
    padding:     10px;
    border-top:  1px solid $table-border-top-color;
    text-align:  left;
    font-weight: 600;
  }

  tr {
    display: table-row;
  }

  td {
    padding:    10px;
    border-top: 1px solid $table-border-top-color;
    font-size:  13px;
  }
}

.sortable-column {
  cursor: pointer;
}

.sort-button {
  @include icon-before($fa-var-sort);
  margin-left: 2px;
  cursor:      pointer;
  color:       $icon-color;
}

.in-active {
  color: $icon_disable_color;
}

.drag-icon {
  @include sort-cursor;
  @include grip-icon;

  font-size: 12px;
  color:     $icon-drag;
  margin:    10px;

  &:active {
    @include sort-cursor-active;
  }
}

.draggable {

  tbody, tr {
    outline: none;
  }

  th {
    &:nth-child(1) {
      width: 6%;
    }
  }

  td {
    &:nth-child(1) {
      width: 6%;
    }
  }
}

.draggable-over {
  background-color: $draggable-row-color;
}
